<template>
  <div class="-ml-4 -mr-4">
    <div class="flex-grow overflow-hidden overflow-y-auto">
      <div class="sm:m-4 sm:mt-0">
        <transition
          enter-active-class="default-animation miniFadeInTop"
          leave-active-class="default-animation miniFadeOutBottom"
          mode="out-in"
        >
          <div
            class="my-16 mb-8 mt-0 w-full px-4 text-center font-merri text-xl sm:px-4 xl:text-2xl"
            :key="`${isTrial}-title-message`"
          >
            Whats in your {{ isTrial ? 'taster' : 'monthly' }} box...
          </div>
        </transition>
      </div>
    </div>
    <transition
      enter-active-class="default-animation miniFadeInTop"
      leave-active-class="default-animation miniFadeOutBottom"
      mode="out-in"
    >
      <div
        key="trial-box"
        v-if="isTrial && !isAdjust"
        class="mx-auto mb-8 flex w-full flex-col overflow-hidden rounded-lg border-2 border-blue-200 bg-white/80 sm:w-2/3 sm:flex-row sm:items-stretch"
      >
        <div class="flex-grow p-8">
          <div
            class="flex items-center justify-between font-merri text-lg font-semibold"
          >
            What you get...

            <font-awesome-icon
              class="-mr-3 text-4xl text-orange-300"
              :icon="['far', 'box-open']"
            />
          </div>
          <div
            v-for="(value, key) in groupProducts(subscriptionTrialProducts)"
            :key="`cat-${index}-${key}`"
            class="mt-2 flex w-full flex-col border-b-2 pb-3"
            :class="{ 'border-transparent': key === 'Dry Food' }"
          >
            <div class="mb-2 mt-2 text-xs uppercase italic text-gray-500">
              {{ key }}
            </div>
            <div
              v-for="product in value"
              :key="`cat-${index}-${product.sku}`"
              class="flex w-full justify-between"
            >
              <div>
                {{ product.shortname || product.name }}
                <span class="ml-1" v-if="product.isKitten">(Kitten)</span>
              </div>
              <div>
                <transition name="fade" mode="out-in">
                  <span
                    class="ml-2 text-gray-600"
                    :key="
                      getQuantity(
                        product.increments,
                        product.quantity,
                        product.foodtype
                      )
                    "
                  >
                    {{
                      getQuantity(
                        product.increments,
                        product.quantity,
                        product.foodtype
                      )
                    }}
                  </span>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-md px-4">
          <div
            class="flex h-full w-full flex-col border-blue-200 py-6 text-sm sm:w-64 sm:border-l-2"
          >
            <div class="flex-grow">
              <div class="px-2 py-2 sm:px-6">
                We hope {{ catsNames() }}
                {{ cats.length | pluralize(['enjoys', 'enjoy']) }} their food.
              </div>
              <div
                class="-ml-4 -mr-4 mt-2 bg-blue-100 p-4 px-6 font-semibold italic sm:ml-0"
              >
                If for any reason they don't, you can cancel any time before
                your 2 week trial ends.

                <div class="mt-2">Super easy, we promise.</div>
              </div>
            </div>
            <div class="px-2 sm:px-6">
              <div class="mb-4 mt-6 font-semibold sm:mt-0">As a member...</div>
              <!-- <div class="mb-2">
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="text-teal-400 mr-2 text-lg"
                />
                Free UK Delivery
              </div> -->
              <div class="mb-2">
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="mr-2 text-lg text-teal-400"
                />
                Adjust / Cancel anytime
              </div>
              <div class="flex flex-row">
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="mr-3 text-lg text-teal-400"
                />
                5 pouches to a shelter every month
              </div>
            </div>
          </div>
        </div>
      </div>
      <div key="cat-subs" v-else>
        <div
          class="mx-auto w-full flex-col items-stretch justify-center space-y-4 sm:w-1/2 sm:flex-row"
        >
          <div
            class="col-span-4 flex w-full flex-col overflow-hidden rounded-lg border-2 sm:col-span-4"
          >
            <div class="divide-y-2 divide-dashed">
              <div v-for="(cat, index) in cats" :key="cat.name" class="w-full">
                <cat-subscription-mini
                  :position="index"
                  :cat="cat"
                  :plan="currentSubscriptionCats[index]"
                  :has-actions="false"
                  :isActive="usersSubscription.active ? cat.active : true"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col space-y-4 pt-4" v-if="canUpgrade">
            <div class="pb-4">
              <div class="text-center text-xl font-semibold">
                Want your food delivered faster?
              </div>
              <div class="px-8 text-center text-sm text-gray-600">
                Upgrade your delivery option and get your food faster each
                month!
              </div>
            </div>
            <delivery-options
              :subscription-price="currentSubscription.intTotal"
              :active-option="this.usersSubscription.deliveryTypeUserSelected"
              @input="setActiveShipping"
              @set-hide="setHide"
            />
          </div>
        </div>
      </div>
    </transition>

    <div class="">
      <div v-if="currentSubscription">
        <div
          v-if="!isAdjust && !user.hadSampleBox && !user.hadSubscription"
          class="-mt-6 px-2 sm:px-0"
        >
          <div class="text-center text-4xl text-gray-700">+</div>
          <div
            class="cat-card relative mb-4 flex h-24 w-full items-center justify-center overflow-hidden rounded-lg bg-gray-100 p-6 text-center shadow-xl sm:mx-auto"
          >
            <div class="relative z-20">
              <div class="font-semibold">
                Incl. FREE Tasters of other flavours
              </div>
            </div>
            <div
              class="absolute right-0 top-0 z-10 flex h-full w-full justify-end"
            >
              <img src="/images/food-bg.png" class="h-full object-cover" />
            </div>
          </div>
        </div>
        <div class="mb-4 text-center">
          <div class="mt-8 text-lg sm:mb-0">
            <div v-if="isAdjust">Total for subscription</div>
            <div v-else>Today you pay</div>
          </div>

          <div
            v-if="!isAdjust && !user.hadSubscription && discountAmount.isValid"
            class="mini-rotate color-transition flex flex-none items-center justify-center rounded-full p-4 py-1 text-base font-normal"
          >
            <span class="mr-2 text-xl font-bold">
              {{ discountAmount.displayCost }}</span
            >
            <span class="italic"> off your first month</span>
          </div>
          <div class="relative">
            <div class="tippy-yellow">
              <transition
                enter-active-class="default-animation miniFadeInTop"
                leave-active-class="default-animation miniFadeOutBottom"
                mode="out-in"
              >
                <div
                  class="inline-block px-2 text-center text-5xl font-semibold"
                  :key="currentSubscription.total"
                >
                  <div
                    :class="{
                      'mt-2 text-xl font-normal text-gray-600 line-through':
                        creditCost && !isAdjust
                    }"
                  >
                    {{ currentSubscription.total }}
                  </div>
                  <div
                    v-if="creditCost && !isAdjust"
                    class="mb-4 flex flex-col items-center space-y-2"
                  >
                    <div>{{ creditCost }}</div>
                    <div
                      class="relative mx-1 flex inline-flex items-center space-x-2 rounded bg-blue-100 p-1 px-2 text-sm font-semibold text-blue-600"
                    >
                      <font-awesome-icon
                        class="text-blue-600"
                        :icon="['far', 'coins']"
                      />
                      <div>Credit applied!</div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <!-- <div class="mb-4">
            Including...
          </div>
          <div class=" mx-auto flex flex-col-reverse flex-row mb-4">
            <div v-if="!isTrial">
              <font-awesome-icon
                :icon="['far', 'check']"
                class="text-teal-400 mr-2"
              />
              {{ subscription.totalShelterPouches }} pouches to a shelter every
              month
            </div>

             <div>
              <font-awesome-icon
                :icon="['far', 'check']"
                class="text-teal-400 mr-2"
              />
              Free UK Delivery
            </div> 
          </div> -->
          <div v-if="hasDeliveryCost" class="text-gray-600">
            (+ {{ hasDeliveryCost.displayCost }} delivery)
          </div>

          <div class="mt-8" v-if="!isAdjust && !user.hadSubscription">
            After your first month you will pay
            {{ subscription.total }} per month.
          </div>
          <div v-if="!isAdjust" class="text-center">
            Remember... You can adjust or cancel your subscription anytime!
          </div>
        </div>
        <div class="mx-auto mt-6 flex justify-center px-2 pb-0 sm:px-0">
          <form @submit.prevent="setAction" class="w-full sm:w-56">
            <loading-button
              :is-loading="isLoading"
              :is-enabled="true"
              :secondary="true"
              is-full
            >
              <div class="py-0">
                <span v-if="!isAdd">
                  <span v-if="isAdjust && !isRestart"> Update </span>
                  <span v-else>
                    {{
                      !isTrial ? 'Start subscription' : 'Get your taster box'
                    }}
                  </span>
                </span>
                <span v-else>Add cat</span>

                <font-awesome-icon
                  :icon="['far', 'arrow-right-long']"
                  class="ml-2"
                />
              </div>
            </loading-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/utils';
import http from '@/http';
import { groupBy, join, find, cloneDeep, includes } from 'lodash/fp';
import LoadingButton from '@/components/LoadingButton';
import { mapGetters } from 'vuex';
import CatSubscriptionMini from '@/components/CatSubscriptionMini';
import DeliveryOptions from './delivery/DeliveryOptions.vue';
export default {
  data() {
    return {
      isTrial: false,
      isLoading: false,
      activeShipping: null,
      showDelivery: true
    };
  },
  props: {
    subscription: Object,
    subscriptionTrial: Object,
    subscriptionTrialProducts: Array,

    cats: Array,
    isAdjust: Boolean,
    plans: Object,
    isAdd: Boolean,
    catIndex: Number,
    currentSubscriptionCats: Array
  },
  components: {
    LoadingButton,
    CatSubscriptionMini,
    DeliveryOptions
  },

  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    currentSubscription() {
      return this.subscription;
    },
    isRestart() {
      return this.$route?.query?.restart;
    },
    ...mapGetters({
      user: 'getCurrentUser',
      discountAmount: 'getDiscountAmount',
      isFirstMonth: 'isFirstMonth',
      usersSubscription: 'getCurrentSubscription',
      products: 'getProducts'
    }),
    hasUpgradedShipping() {
      if (this.usersSubscription?.deliveryTypeIsPaid) {
        return false;
      } else {
        return (
          this.activeShipping?.prices[0].id !==
          this.usersSubscription?.deliveryTypeUserSelected
        );
      }
    },
    hasDeliveryCost() {
      if (this.usersSubscription?.deliveryTypeIsPaid) {
        // const delivery = this.usersSubscription?.deliveryType?.prices.find(
        //   o => o.id === this.usersSubscription?.deliveryTypeUserSelected
        // );
        const deliveryCost = this.usersSubscription?.deliveryTypeIsPaid
          ? this.usersSubscription.isRemote
            ? 299
            : 199
          : 0;

        if (deliveryCost === 0) {
          return false;
        } else {
          return {
            cost: deliveryCost,
            displayCost: utils.getDisplayCost(deliveryCost, 0, true)
          };
        }
      } else {
        return false;
      }
    },
    creditCost() {
      if (this.usersSubscription.credit > 0) {
        return this.$options.filters.formatPounds(
          this.currentSubscription.intTotal - this.usersSubscription.credit
        );
      }

      return null;
    },
    canUpgrade() {
      return (
        !this.usersSubscription?.deliveryTypeIsPaid &&
        !this.usersSubscription?.isRemote &&
        !this.usersSubscription?.oldUser &&
        !this.usersSubscription?.deliveryTypeFreeUpgrade
      );
    }
  },
  watch: {
    isTrial(value) {
      if (value) {
        this.currentSubscription = this.subscriptionTrial;
        utils.setStorage('isTrial', value, 'local');
        utils.setStorage('plans', this.currentSubscription, 'local');
      } else {
        utils.setStorage('isTrial', value, 'local');
        utils.setStorage('plans', this.currentSubscription, 'local');
      }
    }
  },
  mounted() {
    utils.setStorage('isTrial', this.isTrial, 'local');

    utils.setStorage('plans', this.currentSubscription, 'local');
    const delivery = cloneDeep(this.usersSubscription.deliveryType);
    delivery.prices = delivery.prices.filter(
      (o) => o.id === this.usersSubscription.deliveryTypeUserSelected
    );
    this.activeShipping = delivery;
    window.scrollTo(0, 0);
  },
  methods: {
    setActiveShipping(val) {
      this.activeShipping = val;
    },
    setHide(val) {
      this.showDelivery = val;
    },
    getColor(index, type, value) {
      let color = utils.getCatColor(index, value);

      return color[type];
    },
    catsNames() {
      const cats = [];
      this.cats.forEach((c) => {
        cats.push(c.name);
      });
      return join(', ', cats);
    },
    getDiscount(amount) {
      const discount = utils.calcDiscount(amount);
      return '£' + discount.toFixed(2);
    },
    toggleTrial(val) {
      if (val === 'monthly') {
        this.isTrial = false;
      } else {
        this.isTrial = true;
      }
    },
    groupProducts(products) {
      const viewOrder = ['Pouches', 'Trays', 'Dry Food'];
      let productsList = [];
      products.forEach((product) => {
        let prod = this.getSku(product.sku);
        prod.quantity = product.quantity;
        productsList.push(prod);
      });

      productsList.forEach((item) => {
        item.displayType = utils.formatFoodTypeName(item.foodtype);
      });
      productsList = groupBy('displayType', productsList);
      let obj = {};

      viewOrder.forEach((g) => {
        if (productsList[g]) {
          obj[g] = productsList[g];
        }
      });
      productsList = obj;

      return productsList;
    },
    getSku(sku) {
      return utils.skuToProduct(sku);
    },
    changeCat(index) {
      if (this.isAdd) {
        this.$emit('edit-food');
      } else {
        this.$emit('edit-cat', index);
      }
    },
    getQuantity(increments, quantity, type) {
      if (this.isTrial) {
        const total = quantity;
        if (type === 'dry') {
          if (total < 1000) {
            return total + 'g';
          } else {
            return total / 1000 + 'kg';
          }
        } else {
          const adjusted = total;
          return 'x ' + adjusted;
        }
      } else {
        const total = increments * quantity;
        if (type === 'dry') {
          if (total < 1000) {
            return total + 'g';
          } else {
            return total / 1000 + 'kg';
          }
        } else {
          return 'x ' + total;
        }
      }
    },

    setAction: function () {
      let self = this;
      if (!this.isAdjust) {
        if (this.$gtag) {
          this.$gtag.event('begin_checkout', {
            items: utils.getSubscriptionItems(this.cats)
          });
        }

        if (this.isLoggedIn) {
          this.$router
            .push({
              name: 'delivery'
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$router
            .push({
              name: 'create account'
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        if (this.isRestart) {
          this.$router
            .push({
              name: 'delivery',
              params: {
                cats: this.cats
              },
              query: {
                restart: true
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.isLoading = true;
          if (this.isAdd) {
            this.$emit('update-sub', this.cats[0]);
          } else {
            let data = {
              items: this.currentSubscriptionCats,
              delivery: this.usersSubscription.active
                ? includes(this.activeShipping.courier, ['DPD', 'Amazon'])
                  ? 'dpd_2^12_199'
                  : this.usersSubscription.deliveryTypeUserSelected ||
                    'yodel_48_free'
                : 'yodel_48_free'
            };

            data.items.forEach((value) => {
              const activeCat = find(['id', value.id], self.cats);

              if (activeCat) {
                value.products = activeCat.products;
                value.active = true;
              } else {
                value.active = false;
              }
            });

            http
              .put(this.usersSubscription.links.adjust, data)
              .then(() => {
                this.isLoading = false;
                this.$router.push({
                  name: 'dashboard',
                  params: {
                    isSuccessUpdate: true
                  }
                });
                // this.$store.commit('update_subscription', response);
                // this.$ma.trackEvent({
                //   category: 'Click',
                //   action: 'Adjust Success',
                //   properties: {
                //     change:
                //       this.usersSubscription.totalDisplayCost +
                //       ' --> ' +
                //       this.currentSubscription.totalTotal
                //   }
                // });
                this.$store.dispatch('sendMpEvent', {
                  event: 'Adjust Success',
                  properties: {
                    location: self.$route.name,
                    type: 'EVT',
                    delivery: {
                      cost: self.hasDeliveryCost?.displayCost,

                      upgrade: self.hasUpgradedShipping
                    }
                  }
                });
              })
              .catch((err) => {
                this.isLoading = false;
                throw Error(err.response.data.error.message);
              });
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cat-card {
  max-width: 400px;
  width: auto;
}

.summary-sec {
  max-width: 280px;
  width: auto;
}
</style>
