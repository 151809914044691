<template>
  <div
    class="w relative h-2 w-full overflow-hidden rounded-full"
    :class="`bg-gray-200`"
  >
    <div
      class="absolute left-0 h-full rounded-full bg-teal-400"
      :style="styleObj"
    ></div>
  </div>
</template>

<script>
import utils from "@/utils";
export default {
  props: {
    current: {
      type: Number,
    },
    max: {
      type: Number,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    percentage() {
      return utils.getPercentageOf(this.max, this.current).toFixed();
    },
    styleObj() {
      return {
        width: this.active
          ? `${utils.getPercentageOf(this.max, this.current)}%`
          : "0%",
        minWidth: this.active ? `10px` : "0%",
      };
    },
  },
};
</script>

<style></style>
