<template>
  <div class="w-7 h-7 color-transition">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 62.55 64.55"
      :class="color"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            class="knob-bg color-transition"
            d="M59.53,52.21c1.95-2.87,7.2-42.69-3.47-48.55C50.8.77,34.39,14.06,34.39,14.06S18.79-1.93,12.72.19C-1.47,5.14-4.21,49.62,6.65,60,16.31,69.24,52,63.25,59.53,52.21Z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: ['color']
};
</script>

<style lang="scss" scoped>
.knob-bg {
  fill: currentColor;
}
</style>
