<template>
  <div class="flex flex-grow flex-col items-center md:flex-row">
    <div
      class="relative mb-4 mt-2 flex w-full flex-grow items-center justify-end leading-none md:mb-0 md:mt-0"
    >
      <div class="absolute left-0 mr-1 flex items-center justify-end space-x-3">
        <transition name="fade" mode="out-in">
          <div
            v-if="minValue !== 0"
            :key="hitMin"
            class="quick-transition flex items-center justify-end space-x-1"
          >
            <div
              :class="!hitMin ? 'opacity-100' : 'opacity-0'"
              class="h-2 w-6 rounded bg-gradient-to-l from-teal-400"
            ></div>
          </div>
        </transition>
      </div>
      <div
        class="default-transition absolute flex w-full flex-grow items-center space-x-1"
      >
        <div class="default-transition relative flex flex-grow">
          <div
            class="quick-transition absolute left-0 -mt-5 text-sm font-normal italic text-gray-500"
          >
            {{ minValue }}
          </div>

          <div
            class="quick-transition absolute right-0 -mr-3 -mt-4 text-xs font-normal italic text-gray-400"
          >
            {{ ceilingAmount - increments }}
          </div>

          <progress-bar :current="baseVal" :max="increments" />
        </div>
        <div class="default-transition relative flex-grow">
          <div
            class="quick-transition absolute right-0 -mt-5 text-sm font-normal italic text-gray-500"
          >
            {{ hitMax ? `MAX ${ceilingAmount}` : ceilingAmount }}
          </div>

          <progress-bar
            :active="baseVal > secondMax"
            :current="baseVal - increments"
            :max="secondMax"
          />
        </div>
      </div>
      <div class="absolute right-0 ml-1 flex items-center space-x-3">
        <div class="quick-transition flex items-center space-x-1">
          <div
            :class="!hitMax ? 'opacity-100' : 'opacity-0'"
            class="h-2 w-6 rounded bg-gradient-to-r from-gray-200"
          ></div>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-none items-center md:w-auto">
      <div class="flex flex-grow md:hidden">Total</div>
      <div
        class="flex w-full items-center justify-between space-x-2 text-base leading-none"
      >
        <div class="flex-grow text-right md:w-20">
          <span>{{ totalWet }}</span
          ><span class="font-normal text-gray-500">
            / {{ totalWet > minValue ? ceilingAmount : minValue }}</span
          >
        </div>
        <font-awesome-icon
          class="text-2xl"
          :key="fullBox"
          :class="fullBox ? 'text-teal-400' : 'text-gray-500'"
          fixed-width
          :icon="['fal', fullBox ? 'box-circle-check' : 'box-open']"
        />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { sum } from "lodash/fp";
import ProgressBar from "@/components/ProgressBar";

export default {
  name: "AmountTracker",
  props: {
    amounts: {
      type: Object,
    },
  },
  data() {
    return {
      increments: 15,
    };
  },
  components: {
    ProgressBar,
  },
  computed: {
    totalWet() {
      return sum([
        this.amounts?.wet_tray,
        this.amounts?.wet_can,
        this.amounts?.wet_smooth,
        this.amounts?.wet_pouch,
        this.amounts?.wet_tasty,
        this.amounts?.wet_chunk,
      ]);
    },
    minValue() {
      if (this.ceilingAmount === 60) {
        return 30;
      }
      if (this.ceilingAmount === 90) {
        return 60;
      }
      if (this.ceilingAmount === 120) {
        return 90;
      }

      return 0;
    },
    hitMax() {
      return this.ceilingAmount === 120;
    },
    hitMin() {
      return this.minAount === 0;
    },
    fullBox() {
      if (this.totalWet >= 30) {
        return this.totalWet % 15 === 0;
      } else {
        return false;
      }
    },
    ceilingAmount() {
      const amount = Math.ceil(this.totalWet / 30) * 30;
      if (amount < 30) {
        return 30;
      }
      if (this.totalWet === 90) {
        return 120;
      }
      if (this.totalWet === 60) {
        return 90;
      }
      if (this.totalWet === 30) {
        return 60;
      }

      return amount;
    },
    firstMax() {
      if (this.ceilingAmount === 60) {
        return this.ceilingAmount - 15;
      }
      if (this.ceilingAmount === 90) {
        return this.ceilingAmount - 15;
      }
      if (this.ceilingAmount === 120) {
        return this.ceilingAmount - 15;
      }

      return this.ceilingAmount / 2;
    },
    secondMax() {
      if (this.ceilingAmount === 60) {
        return 30 - this.increments;
      }
      if (this.ceilingAmount === 90) {
        return 30 - this.increments;
      }
      if (this.ceilingAmount === 120) {
        return 30 - this.increments;
      }

      return this.ceilingAmount - this.increments;
    },
    baseVal() {
      if (this.ceilingAmount === 60) {
        return this.totalWet - 30;
      }
      if (this.ceilingAmount === 90) {
        return this.totalWet - 60;
      }
      if (this.ceilingAmount === 120) {
        return this.totalWet - 90;
      }
      return this.totalWet;
    },
  },
};
</script>

<style></style>
