<template>
  <div class="space-y-12">
    <div class="flex justify-center text-gray-700">
      <div class="flex w-full space-x-2 rounded-lg bg-gray-200 p-2">
        <div
          @click="setOption('pouches')"
          class="relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300"
          :class="
            foodType === 'pouches'
              ? `bg-${coreColor}-400 text-white hover:bg-${coreColor}-400 cursor-default`
              : ''
          "
        >
          <div class="mx-auto">Pouches</div>
        </div>
        <div
          @click="setOption('cans')"
          class="relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300"
          :class="
            foodType === 'cans'
              ? `bg-${coreColor}-400 text-white hover:bg-${coreColor}-400 cursor-default`
              : ''
          "
        >
          <div class="mx-auto">Deluxe Cans / Trays</div>
        </div>
      </div>
    </div>
    <div class="space-y-4 px-2">
      <div>
        <div class="mb-2 mt-4 flex justify-between">
          <div>
            <span class="font-semibold">Wet Food</span>
          </div>
          <div class="w-12 text-center text-xl font-semibold sm:text-xl">
            {{ cat.wetFood }}
          </div>
        </div>
        <div class="flex items-center space-x-6 leading-none">
          <div class="flex-grow">
            <range-slider
              class="slider w-full"
              :class="`slider-${coreColor}`"
              min="30"
              max="120"
              step="30"
              v-model="cat.wetFood"
              @change="getPrice"
            >
              <template v-slot:knob
                ><slider-knob class="animated shake" :color="currentColor"
              /></template>
            </range-slider>
          </div>
        </div>
      </div>
      <div>
        <div class="mb-2 flex justify-between">
          <div>
            <span class="font-semibold">Dry Food</span>
          </div>
          <div class="w-12 text-center text-xl font-semibold sm:text-xl">
            {{ dryFoodFormatted }}
          </div>
        </div>
        <div class="flex items-center space-x-6 leading-none">
          <div class="flex-grow">
            <range-slider
              class="slider w-full"
              :class="`slider-${coreColor}`"
              min="0"
              max="3200"
              step="800"
              v-model="cat.dryFood"
              @change="getPrice"
            >
              <template v-slot:knob
                ><slider-knob
                  class="animated shake color-transition"
                  :color="currentColor"
              /></template>
            </range-slider>
          </div>
        </div>
      </div>
    </div>
    <div class="color-transition p-6 px-4 py-0">
      <div class="mx-auto text-center text-sm italic text-gray-700">
        Give your cat Healthy food each month <br />& never run out again…
      </div>

      <transition
        enter-active-class="miniFadeInTop"
        leave-active-class="miniFadeOutBottom"
        mode="out-in"
      >
        <div
          v-if="subscriptionCost"
          class="py-1 text-center text-5xl font-semibold"
          :key="subscriptionCost.total"
        >
          {{ subscriptionCost.total }}
        </div>
        <div
          class="py-4 pb-0 pb-2 text-center text-5xl font-semibold sm:text-6xl"
          v-else
        >
          £--
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import RangeSlider from "vue-range-slider";
import SliderKnob from "@/components/pricing/SliderKnob";
import { mapActions } from "vuex";
import http from "@/http";
import { split } from "lodash/fp";
export default {
  props: ["color"],
  components: {
    RangeSlider,
    SliderKnob,
  },
  data() {
    return {
      cat: {
        wetFood: 60,
        dryFood: 0,
      },
      foodType: "pouches",
      type: "Pouches",
      isLoading: false,
      subscriptionCost: null,
    };
  },
  computed: {
    currentColor() {
      return `text-${split("-", this.color)[1]}-400`;
    },
    coreColor() {
      return split("-", this.color)[1];
    },
    dryFoodFormatted() {
      const formatted = this.cat.dryFood / 1000;

      if (this.cat.dryFood < 1000) {
        if (this.cat.dryFood === 0) {
          return "0";
        } else {
          return `${this.cat.dryFood}g`;
        }
      } else {
        return `${formatted}kg`;
      }
    },
  },
  watch: {
    type(value) {
      if (value) {
        this.getPrice();
      }
    },
  },
  methods: {
    ...mapActions({
      sendEvent: "sendMpEvent",
    }),
    setOption(type) {
      this.foodType = type;
      this.getPrice();
    },
    getPrice() {
      this.isLoading = true;
      const data = {
        cats: [
          {
            products: [],
          },
        ],
      };

      if (this.cat.dryFood !== 0) {
        data.cats[0].products.push({
          sku: "D_BAG_CHK",
          quantity: this.cat.dryFood / 800,
        });
      }
      if (this.cat.wetFood !== 0) {
        data.cats[0].products.push({
          sku: this.foodType === "pouches" ? "W_PCH_CHK" : "W_CAN_CHK_DLX",
          quantity: this.cat.wetFood / 5,
        });
      }
      http
        .post("/subscription_product_preview", data)
        .then((response) => {
          this.isLoading = false;
          this.subscriptionCost = response.data.content[0];
          this.sendEvent({
            event: "Pricing calculator update",
            properties: {
              location: this.$route.name,
              wetType: this.type,
              wetAmount: this.cat.wetFood,
              dryAmount: this.dryFoodFormatted,
              totalCost: this.subscriptionCost.totalTotal,
              type: "EVT",
            },
          });
        })
        .catch((err) => {
          this.isLoading = false;
          throw Error(err.response.data.error.message);
        });
    },
  },
  mounted() {
    this.getPrice();
  },
};
</script>

<style lang="scss">
$slider-height: 20px;
$slider-width: 130px;
$rail-height: 10px;
$knob-size: 30px;
$knob-color: none;

$knob-border: none;
$knob-shadow: none;
// import the built-in vue-range-slider style
@import "~vue-range-slider/dist/vue-range-slider.scss";

input[type="text"].range-slider-hidden {
  display: none;
}

.range-slider-rail {
  height: 8px;
  background-color: #e5e7eb;
}

.range-slider-fill {
  transition-property: background-color;
  transition-duration: 3s;
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.slider-teal .range-slider-fill {
  background-color: #5eead4;
}
.slider-teal .selected-dry {
  border: 2px solid #5eead4;
}

.slider {
  width: 100%;
  min-width: 100%;
}
.range-slider {
  padding: 0;
}
</style>
