<template>
  <div class="py-16">
    <h1 class="mb-16 mb-4 text-center font-merri text-4xl">Pricing</h1>
    <div class="container mx-auto space-y-12">
      <div class="mx-auto w-full space-y-4 px-4 text-left sm:w-1/2">
        <h2 class="text-center text-xl font-semibold xl:text-2xl">
          Its time for proper food...
        </h2>
        <p>
          Most commercial food is bulked out with variable meat quality, sugar
          and cheap carbs. We do the opposite. We use Premium ingredients which,
          although cost more, help your cat’s delicate tummy and overall health
          stay at its very best. It’s peace of mind into every bowl.
        </p>
      </div>
    </div>
    <div class="mt-8 text-teal-300">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          class="filler color-transition"
          d="M0,192L30,170.7C60,149,120,107,180,101.3C240,96,300,128,360,144C420,160,480,160,540,181.3C600,203,660,245,720,240C780,235,840,181,900,138.7C960,96,1020,64,1080,90.7C1140,117,1200,203,1260,234.7C1320,267,1380,245,1410,234.7L1440,224L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
        ></path>
      </svg>
    </div>
    <div class="color-transition relative bg-teal-300">
      <div class="container relative mx-auto -mt-1 sm:w-1/3">
        <div class="space-y-4 pt-8 sm:pt-0">
          <div
            class="has-blue w-full space-y-4 px-2 pb-8 pt-6 text-left text-center"
          >
            <h2 class="text-center text-xl font-semibold xl:text-2xl">
              Personalised to your cat
            </h2>
            <p>
              We offer complete flexibility, so you can order just one type of
              food (pouches, trays, dry food) or create any combination to suit
              your cat.
            </p>
            <p>
              Use our handy calculator to try different options to suit your
              cat.
            </p>
          </div>
          <div class="has-blue space-y-2 pb-6 text-center">
            <span
              class="border-b border-dashed border-[#0b315e] pb-1 text-center font-semibold"
              @click="show2Cat = true"
            >
              Got 2 or more cats?
            </span>
            <div v-if="show2Cat" class="space-y-4 px-6 pt-6">
              <div class="text-sm">
                You pay the total amount of pouches in your overall order.
              </div>
              <div class="text-sm">
                For example... If Cat 1 is on 30 pouches and Cat 2 is on 30
                pouches the overall price you pay for both cats combined is
                {{ examplePrice }} inc delivery
              </div>
            </div>
          </div>
          <div class="rounded-lg bg-white p-4 pb-8 shadow-2xl sm:p-8 sm:pb-4">
            <food-calculator color="bg-teal-300" class="sm:col-span-2" />
            <div class="mb-4 mt-8 text-center text-sm font-semibold">
              All subscriptions include...
            </div>
            <div
              class="mb-6 grid grid-cols-4 gap-4 text-center text-sm text-gray-700"
            >
              <div
                v-for="(item, index) in included"
                :key="`item-${index}`"
                class="col-span-4 flex items-center space-y-2 px-2 sm:col-span-2 sm:px-0"
              >
                <div class="flex-none">
                  <font-awesome-icon
                    class="mr-3 mt-1"
                    :icon="['far', 'check']"
                    :class="textColor"
                  />
                </div>
                <div class="flex-grow text-left">
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-teal-300">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          class="filler color-transition"
          d="M0,128L34.3,128C68.6,128,137,128,206,112C274.3,96,343,64,411,85.3C480,107,549,181,617,218.7C685.7,256,754,256,823,250.7C891.4,245,960,235,1029,240C1097.1,245,1166,267,1234,256C1302.9,245,1371,203,1406,181.3L1440,160L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
        ></path>
      </svg>
    </div>
    <div
      class="justify-cente container mx-auto mt-8 flex flex-col items-center space-y-8 sm:mt-0"
    >
      <div class="w-full px-1 text-center sm:w-1/2 sm:px-12">
        Our Taster Box is full of our most popular food so they can enjoy
        finding their favourites...
      </div>
      <action-ctas class="w-full sm:flex sm:justify-center" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ActionCtas from "@/components/ActionCtas";
import FoodCalculator from "@/components/FoodCalculator";
export default {
  metaInfo: {
    title: "Pricing - The Cool Cat Club",
  },
  components: {
    ActionCtas,
    FoodCalculator,
  },
  data() {
    return {
      show2Cat: false,
      included: [
        { label: "Flexibility to adjust, pause or cancel your order anytime" },
        {
          label: "Our donation of 5 pouches to a cat in a shelter every month",
        },
        { label: "Customer support 7 days a week" },
        {
          label: "21 different wet food options, 3 different dry foods.",
        },
        {
          label:
            "Need your food earlier? Get it sent out early with Next Day Delivery",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentColor: "currentColorGetter",
      corePrices: "corePricesGetter",
    }),
    examplePrice() {
      const price = this.corePrices.find((cp) => cp.amount === 60);
      return `£${(price.value / 100).toFixed(2)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.filler {
  fill: currentColor;
}
.has-blue {
  color: #0b315e;
}
</style>
