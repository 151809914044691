<template>
  <div
    class="default-transition default-animation relative flex w-full flex-col items-start bg-white px-4 py-4"
  >
    <div class="flex h-full w-full flex-col">
      <div class="relative flex w-full items-stretch text-base font-medium">
        <div class="flex w-full flex-col md:flex-row">
          <div class="flex h-full flex-grow flex-col truncate">
            <div
              class="mb-1 flex w-full flex-grow items-start space-x-4 truncate"
            >
              <div
                class="flex w-full flex-grow flex-row items-center space-x-2"
              >
                <div
                  class="flex-none overflow-hidden rounded-full border-4"
                  :class="
                    !cat.active
                      ? 'border-gray-400 opacity-50 grayscale'
                      : 'border-teal-400'
                  "
                >
                  <img v-if="cat.image" :src="getImage(cat.image)" />
                  <div
                    v-else
                    class="flex aspect-square h-[40px] items-center justify-center rounded-full bg-gray-100 text-gray-300"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'camera']"
                      class="flex group-hover:hidden"
                    />
                  </div>
                </div>
                <div class="flex-grow flex-col truncate">
                  <div class="flex flex-grow items-center space-x-2 truncate">
                    <div class="truncate">{{ cat.name }}</div>
                    <div
                      class="rounded px-2 py-0.5 text-sm font-normal"
                      :class="
                        isActive
                          ? 'bg-teal-100 text-teal-400'
                          : 'bg-gray-200 text-gray-500'
                      "
                    >
                      {{ isActive ? 'Active' : 'Inactive' }}
                    </div>
                  </div>
                  <div class="flex space-x-1 text-sm font-normal text-gray-600">
                    <div class="font-normal">
                      {{
                        cat.type === 'kitten' || cat.isKitten
                          ? 'Kitten'
                          : 'Adult'
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4 flex items-center text-sm font-normal">
              <div class="flex flex-col space-y-2 text-gray-600">
                <div class="whitespace-normal break-words px-2">
                  {{ productString }}
                </div>
                <div
                  class="inline-flex cursor-pointer items-center space-x-2 px-2 text-sm font-normal text-gray-600 hover:underline"
                  @click="toggleInfo"
                >
                  <div>{{ showInfo ? 'Hide details' : 'Show details' }}</div>
                  <font-awesome-icon
                    class="text-xs"
                    :icon="['far', showInfo ? 'chevron-up' : 'chevron-down']"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="mt-6 grid w-full flex-none grid-cols-2 border-t-2 text-center md:ml-4 md:mt-0 md:w-48 md:grid-cols-1 md:border-l-2 md:border-t-0"
            v-if="hasActions"
          >
            <div
              class="col-span-2 flex cursor-pointer items-center justify-center space-x-1 border-b-2 py-3 text-center text-sm font-normal text-gray-600 hover:underline md:col-span-1 md:border-b-0 md:pt-0"
              @click="
                showModal('', 'EditCat', {
                  cat: cat
                })
              "
            >
              Update details
            </div>
            <div
              v-if="canMakeInactive && isActive"
              class="flex cursor-pointer items-center justify-center space-x-2 border-r-2 py-3 text-center text-sm font-normal text-gray-600 hover:underline md:border-r-0 md:border-t-2"
              @click="
                showModal('', 'MakeInactive', {
                  cat: cat,
                  makeActive: false
                })
              "
            >
              <font-awesome-icon class="text-xs" :icon="['far', 'ban']" />
              <div>Make inactive</div>
            </div>
            <div
              v-if="!isActive && subscription.active"
              class="flex cursor-pointer items-center justify-center space-x-2 py-3 text-center text-sm font-normal text-gray-600 hover:underline md:border-t-2"
              @click="
                showModal('', 'MakeInactive', {
                  cat: cat,
                  makeActive: true
                })
              "
            >
              <font-awesome-icon
                class="text-base"
                :icon="['fal', 'thumbs-up']"
              />
              <div>Reactivate</div>
            </div>
            <div
              v-if="canRemove"
              class="flex cursor-pointer items-center justify-center space-x-2 py-3 text-center text-sm font-normal text-red-400 hover:underline md:border-t-2 md:pb-0"
              @click="
                showModal('', 'RemoveCat', {
                  cat: cat
                })
              "
            >
              <font-awesome-icon
                class="text-xs"
                :icon="['far', 'trash-can-xmark']"
              />
              <div>Remove</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showInfo" class="subscription-info flex flex-col px-2">
        <div
          v-for="(value, key) in groupProducts(cat.products)"
          :key="`cat-${position}-${key}`"
          class="mt-2 flex w-full flex-col border-b-2 pb-3"
        >
          <div class="mb-2 mt-2 text-xs uppercase italic text-gray-500">
            <div class="flex">
              <div class="flex-grow">{{ key }}</div>
              <div v-if="getTypeCount(key) === 'dry'">
                {{ productsCount[getTypeCount(key)] | dryFormat }}
              </div>
              <div v-else>{{ productsCount[getTypeCount(key)] }}</div>
            </div>
          </div>
          <div
            v-for="product in value"
            :key="`cat-${position}-${product.sku}`"
            class="flex w-full justify-between"
          >
            <div>
              {{ product.shortname || product.name }}
              <span class="ml-1" v-if="product.isKitten"> (Kitten)</span>
            </div>
            <div>
              <transition name="fade" mode="out-in">
                <span
                  class="ml-2 text-gray-600"
                  :key="
                    getQuantity(
                      product.increments,
                      product.quantity,
                      product.foodtype
                    )
                  "
                >
                  {{
                    getQuantity(
                      product.increments,
                      product.quantity,
                      product.foodtype
                    )
                  }}
                </span>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/utils';
import { mapGetters, mapMutations } from 'vuex';
import { groupBy, filter, find } from 'lodash/fp';
export default {
  props: {
    position: {
      type: Number
    },
    cat: {
      type: Object
    },
    plan: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    isActive: {
      type: Boolean
    },
    isTrial: {
      type: Boolean
    },
    hasActions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      colors: ['teal', 'blue', 'pink'],
      showInfo: false
    };
  },
  computed: {
    ...mapGetters({
      subscription: 'getCurrentSubscription',
      productsList: 'getProducts'
    }),
    productsCount() {
      const totals = {
        wet_pouch: 0,
        wet_tasty: 0,
        wet_chunk: 0,
        wet_tray: 0,
        wet_can: 0,
        wet_smooth: 0,
        dry: 0
      };
      this.cat.products.forEach((p) => {
        const product = find(['sku', p.sku], this.productsList);
        const amount = p.quantity * product?.increments;

        totals[product?.foodtype] += amount;
      });
      return totals;
    },
    productString() {
      const obj = this.productsCount;
      const vals = [];
      const self = this;
      Object.keys(obj).forEach(function (key) {
        if (obj[key] !== 0) {
          const type = utils.foodTypes.find((f) => f.key === key);
          const value = key === 'dry' ? self.formatDry(obj[key]) : obj[key];

          vals.push(`${value} ${type.display}`);
        }
      });

      if (vals.length > 1) {
        return vals.slice(0, -1).join(', ') + ' & ' + vals.slice(-1);
      }
      return vals[0];
    },
    canMakeInactive() {
      const noCats = this.subscription.items.length;
      const inactive = filter(
        ['active', false],
        this.subscription.items
      ).length;
      const count = noCats - inactive;
      if (count != 1) {
        return true;
      } else {
        return false;
      }
    },
    canRemove() {
      if (this.subscription.active) {
        const noCats = this.subscription.items.length;
        const inactive = filter(
          ['active', false],
          this.subscription.items
        ).length;
        const count = noCats - inactive;
        if (noCats > 2) {
          return true;
        } else {
          if ((this.cat.active && count == 1) || count == 0) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return this.subscription.items.length > 1;
      }
    }
  },
  methods: {
    ...mapMutations({
      showActiveModal: 'show_modal'
    }),
    getTypeCount(key) {
      if (key === 'Pouches') {
        return 'wet_pouch';
      }
      if (key === 'Trays') {
        return 'wet_tray';
      }
      if (key === 'Cans') {
        return 'wet_can';
      }
      if (key === 'Smooth') {
        return 'wet_smooth';
      }
      if (key === 'Tasty Slices') {
        return 'wet_tasty';
      }
      if (key === 'Tasty Chunks') {
        return 'wet_chunk';
      }
      if (key === 'Dry Food') {
        return 'dry';
      }
    },
    toggleInfo() {
      this.showInfo = !this.showInfo;
    },
    showModal: function (title, component, data) {
      this.showActiveModal({ title, component, data });
    },
    getColor: function (type) {
      if (type === 'border') {
        return 'border-' + this.colors[this.position] + '-400';
      }
      if (type === 'avatar') {
        return 'text-' + this.colors[this.position] + '-400';
      }
      if (type === 'background') {
        return 'bg-' + this.colors[this.position] + '-100';
      }
    },

    groupProducts(products) {
      const viewOrder = [
        'Pouches',
        'Cans',
        'Tasty Slices',
        'Tasty Chunks',
        'Trays',
        'Dry Food',
        'Smooth'
      ];
      let productsList = [];
      products.forEach((product) => {
        let prod = this.getSku(product?.sku);
        prod.quantity = product?.quantity;
        productsList.push(prod);
      });

      productsList.forEach((item) => {
        item.displayType = utils.formatFoodTypeName(item.foodtype);
      });
      productsList = groupBy('displayType', productsList);
      let obj = {};

      viewOrder.forEach((g) => {
        if (productsList[g]) {
          obj[g] = productsList[g];
        }
      });
      productsList = obj;

      return productsList;
    },
    getSku(sku) {
      return utils.skuToProduct(sku);
    },
    formatDry(amount) {
      if (amount < 1000) {
        return amount + 'g';
      } else {
        return amount / 1000 + 'kg';
      }
    },
    getQuantity(increments, quantity, type) {
      const total = increments * quantity;
      if (type === 'dry') {
        if (total < 1000) {
          return 'x ' + total / increments;
        } else {
          return 'x ' + total / increments;
        }
      } else {
        return 'x ' + total;
      }
    },
    getImage(image) {
      return image + '?tr=fo-cat,ar-1_1,w-40,f-webp';
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar-bg {
  @apply fill-current;
}

.slider {
  @apply w-full #{!important};
}
.cat-card {
  max-width: 400px;
  width: auto;
}

.loading-overlay {
  background: rgba(255, 255, 255, 0.8);
}

.backdrop-blur {
  backdrop-filter: blur(2px);
}

.rotate {
  transform: rotate(-12deg);
}
</style>
